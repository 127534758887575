import { useTranslation } from "react-i18next";

const PhishingTableComponent = ({ user, index }) => {

    // const initialRole = useRef(user.role);

    // const [selectedRole, setSelectedRole] = useState(initialRole.current);
    // const [isChanged, setIsChanged] = useState(false);

    // const [updateUserRole, { isLoading: isUpdateUserRoleLoading }] = useUpdateRoleMutation()

    // const { data: userlogged, isLoading1, isSuccess1 } = useWhoamiQuery();

    const [t, i18n] = useTranslation("common");

    // useEffect(() => {
    //     if (selectedRole !== user.role) {
    //         setIsChanged(true);
    //     } else {
    //         setIsChanged(false);
    //     }
    // }, [selectedRole, user.role]);

    // const handleRoleChange = (e) => {
    //     setSelectedRole(e.target.value);
    // };

    return (
        <>
            <div key={index} style={{ display: "flex", height: 60, padding: 10, backgroundColor: "lightgray" }}>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black" }}>
                    {user.employee.name}
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black" }}>
                    {
                        (() => {
                            if (user.lastAttempt === null) return "Keine Daten vorhanden"
                            let date = new Date(user.lastAttempt);
                            let formattedDate = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
                            return formattedDate;
                        })()
                    }
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", borderRight: "solid 2px black" }}>
                    {
                        (() => {
                            const [frontValue, backValue] = user.statistics.split('/').map(Number);
                            const modifiedStatistics = `${frontValue+5}/${backValue+5}`;
                            return modifiedStatistics;
                        })()
                    }
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        backgroundColor: user.status === "pending" ? 'orange' : (user.status === "passed" ? 'green' : 'red'), width: "50%", color: "white", textAlign: "center", padding: 10, borderRadius: 5, cursor: "pointer"
                    }}
                    // onClick={() => {
                    //     console.log('updateUserRole', user, selectedRole)

                    //     if (user.username === "oberreiter") {
                    //         return;
                    //     }

                    //     updateUserRole({ user: { ...user, role: selectedRole } })
                    // }
                    // }>
                    >
                        {user.status === "pending" ? t("admin.pages.pages-akd-pishing-checkbox-waiting")
                            : (user.status === "passed" ? t("admin.pages.pages-akd-pishing-checkbox-notphished") : t("admin.pages.pages-akd-pishing-checkbox-phished"))}
                    </div>
                </div>
            </div >

        </>
    )

}

export default PhishingTableComponent;